import { Action, createAction, props } from '@ngrx/store';
import { type } from '../../util';
import {TaxType, UserSettings} from '../../models/user-settings';
import {ExternalInvoice} from '../../models/external-invoice';
import {GSettingsTabSelection} from '../../shared/enums';
import { ApiErrorResponse } from 'app/models/apierror';
import { SettingHistoryItem, SettingHistoryPagedRequest } from 'app/models/setting-history';
import { PagedResponse } from 'app/models/pagination';

export const ActionTypes = {
  OPENINVOICEDIALOG: type('[GSettings] Open invoice dialog'),
  OPENINVOICEDIALOGSUCCESS: type('[GSettings] Opening invoice dialog was successful'),
  OPENINVOICEDIALOGFAILED: type('[GSettings] Opening invoice dialog failed'),
  CLOSEINVOICEDIALOG: type('[GSettings] Close invoice dialog'),
  UPDATEGLOBALSETTINGS: type('[GSettings] Updating global settings user info'),
  UPDATEGLOBALSETTINGSSUCCESS: type('[GSettings] Updating global settings user info was successful'),
  UPDATEGLOBALSETTINGSFAILED: type('[GSettings] Updating global settings user info failed'),
  UPDATEGLOBALUSERSETTINGSTAB: type('[GSettings] Updating global user settings tab'),

  SAVEUSERSETTINGSTOLOCALDB: type('[GSettings] Save user settings to local db'),
  SAVEUSERSETTINGSTOLOCALDBSUCCESS: type('[GSettings] Save user settings to local db success'),
  SAVEUSERSETTINGSTOLOCALDBFAILURE: type('[GSettings] Save user settings to local db failure'),

  GETUSERSETTINGS: type('[GSettings] Get User Settings'),
  GETUSERSETTINGSSUCCESS: type('[GSettings] Get User Settings success'),
  GETUSERSETTINGSFAILURE: type('[GSettings] Get User Settings Failure'),

  HIDETRIALBANNER: type('[GSettings] Hide Trial Banner'),

  SHOWCLOSEACCOUNTDIALOG: type('[GSettings] Show close account dialog'),
  SHOWCLOSEACCOUNTDIALOGSUCCESS: type('[GSettings] Show close account dialog success'),
  SHOWCLOSEACCOUNTDIALOGFAILURE: type('[GSettings] Show close account dialog failure'),

  HIDECLOSEACCOUNTDIALOG: type('[GSettings] Hide close account dialog'),

  SUBMITCLOSEACCOUNT: type('[GSettings] Submit close account'),
  SUBMITCLOSEACCOUNTSUCCESS: type('[GSettings] Submit close account success'),
  SUBMITCLOSEACCOUNTFAILURE: type('[GSettings] Submit close account failure'),

  SHOWREACTIVATEACCOUNTDIALOG: type('[GSettings] Show Reactivate account dialog'),
  SHOWREACTIVATEACCOUNTDIALOGSUCCESS: type('[GSettings] Reactivate account dialog success'),
  SHOWREACTIVATEACCOUNTDIALOGFAILURE: type('[GSettings] Reactivate account dialog failure'),

  HIDEREACTIVATEACCOUNTDIALOG: type('[GSettings] Hide Reactivate account dialog'),

  UPLOADLOGOREQUEST: type('[GSettings] Upload Logo Request'),
  UPLOADLOGOSUCCESS: type('[GSettings] Upload Logo Success'),
  UPLOADLOGOFAILURE: type('[GSettings] Upload logo failure'),

  SHOWUPDATETAXTYPEDIALOG: type('[GSettings] Show Update Tax Type Dialog'),
  SHOWDELETETAXTYPESDIALOG: type('[GSettings] Show Delete Tax Types Dialog'),

  UPDATETAXTYPE: type('[GSettings] Update Tax Type'),
  UPDATETAXTYPESUCCESS: type('[GSettings] Update Tax Type Success'),
  UPDATETAXTYPEFAILURE: type('[GSettings] Update Tax Type Failure'),
  UPDATETAXTYPECOMPLETE: type('[GSettings] Update Tax Type Complete'),

  DELETETAXTYPES: type('[GSettings] Delete Tax Types'),
  DELETETAXTYPESSUCCESS: type('[GSettings] Delete Tax Types Success'),
  DELETETAXTYPESFAILURE: type('[GSettings] Delete Tax Types Failure'),
  DELETETAXTYPESCOMPLETE: type('[GSettings] Delete Tax Types Complete'),

  GETSETTINGHISTORY: type('[GSettings] Get Setting History'),
  GETSETTINGHISTORYSUCCESS: type('[GSettings] Get Setting History Success'),
  GETSETTINGHISTORYFAILURE: type('[GSettings] Get Setting History Failure'),
  RESETSETTINGHISTORYSTATUS: type('[GSettings] Reset Setting History Status'),

  RESETSETTINGSTATUS: type('[GSettings] Reset Setting Status'),
};


export const GetSettingHistory = createAction(ActionTypes.GETSETTINGHISTORY, props<{requestBody: SettingHistoryPagedRequest}>());
export const GetSettingHistorySuccess = createAction(ActionTypes.GETSETTINGHISTORYSUCCESS, props<{history: PagedResponse<SettingHistoryItem>}>());
export const GetSettingHistoryFailure = createAction(ActionTypes.GETSETTINGHISTORYFAILURE, props<{error: ApiErrorResponse}>());
export const ResetSettingHistoryStatus = createAction(ActionTypes.RESETSETTINGHISTORYSTATUS);
export const ResetGlobalSettingsStatus = createAction(ActionTypes.RESETSETTINGSTATUS);


/* The action gets called when the user clicks on any of the 3 invoice provider buttons */
export class OpenInvoiceDialogAction implements Action {
  type = ActionTypes.OPENINVOICEDIALOG;

  constructor(public payload: ExternalInvoice) {}
}
/* On success display the dialog */
/* On success display the dialog */
export class OpenInvoiceDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENINVOICEDIALOGSUCCESS;

  constructor(public payload: any) { }
}
/* On success display the error */
export class OpenInvoiceDialogFailedAction implements Action {
  type = ActionTypes.OPENINVOICEDIALOGFAILED;

  constructor(public payload: any) { }
}
/* On failure, after 5 seconds of showing the loading icon it displays a warning sign */
export class CloseInvoiceDialogAction implements Action {
  type = ActionTypes.CLOSEINVOICEDIALOG;

  constructor(public payload?: any) { }
}
export class UpdateGlobalUserSettings implements  Action {
  type = ActionTypes.UPDATEGLOBALSETTINGS;

  constructor(public payload: any) { }
}

export class UpdateGlobalUserSettingsSuccess implements  Action {
  type = ActionTypes.UPDATEGLOBALSETTINGSSUCCESS;

  constructor(public payload: any) { }
}

export class UpdateGlobalUserSettingsFailed implements  Action {
  type = ActionTypes.UPDATEGLOBALSETTINGSFAILED;

  constructor(public payload: any) { }
}

export class UpdateGlobalUserSettingsTab implements Action {
  type = ActionTypes.UPDATEGLOBALUSERSETTINGSTAB;

  constructor(public payload: GSettingsTabSelection) { }
}

export class GetUserSettingsAction implements Action {
  type = ActionTypes.GETUSERSETTINGS;
  constructor(public payload?: any) { }
}

export class GetUserSettingsSuccessAction implements Action {
  type = ActionTypes.GETUSERSETTINGSSUCCESS;
  constructor(public payload: UserSettings) { }
}

export class GetUserSettingsFailureAction implements Action {
  type = ActionTypes.GETUSERSETTINGSFAILURE;
  constructor(public payload: any) { }
}

export class HideTrialBanner implements Action {
  type = ActionTypes.HIDETRIALBANNER;
  constructor(public payload?: any) { }
}

export class ShowCloseAccountDialogAction implements Action {
  type = ActionTypes.SHOWCLOSEACCOUNTDIALOG;
  constructor(public payload?: any) { }
}

export class ShowCloseAccountDialogSuccessAction implements Action {
  type = ActionTypes.SHOWCLOSEACCOUNTDIALOGSUCCESS;
  constructor(public payload?: any) { }
}

export class ShowCloseAccountDialogFailureAction implements Action {
  type = ActionTypes.SHOWCLOSEACCOUNTDIALOGFAILURE;
  constructor(public payload?: any) { }
}

export class HideCloseAccountDialogAction implements Action {
  type = ActionTypes.HIDECLOSEACCOUNTDIALOG;
  constructor(public payload?: any) { }
}

export class SubmitCloseAccountAction implements Action {
  type = ActionTypes.SUBMITCLOSEACCOUNT;
  constructor(public payload?: any) { }
}

export class SubmitCloseAccountSuccessAction implements Action {
  type = ActionTypes.SUBMITCLOSEACCOUNTSUCCESS;
  constructor(public payload?: any) { }
}

export class SubmitCloseAccountFailureAction implements Action {
  type = ActionTypes.SUBMITCLOSEACCOUNTFAILURE;
  constructor(public payload?: any) { }
}

export class ShowReactivateAccountDialogAction implements Action {
  type = ActionTypes.SHOWREACTIVATEACCOUNTDIALOG;

  constructor(public payload?: any) { }
}

export class ShowReactivateAccountDialogSuccessAction implements Action {
  type = ActionTypes.SHOWREACTIVATEACCOUNTDIALOGSUCCESS;

  constructor(public payload: boolean) { }
}

export class ShowReactivateAccountDialogFailureAction implements Action {
  type = ActionTypes.SHOWREACTIVATEACCOUNTDIALOGFAILURE;

  constructor(public payload?: any) { }
}

export class HideReactivateAccountDialogAction implements Action {
  type = ActionTypes.HIDEREACTIVATEACCOUNTDIALOG;

  constructor(public payload?: any) { }
}

export class UploadLogoRequestAction implements Action {
  type = ActionTypes.UPLOADLOGOREQUEST;

  constructor(public  payload: File) {}
}

export class UploadLogoSuccessAction implements Action {
  type = ActionTypes.UPLOADLOGOSUCCESS;

  constructor(public payload: { logoUrl: string }) {}
}

export class UploadLogoFailureAction implements Action {
  type = ActionTypes.UPLOADLOGOFAILURE;
  
  constructor(public payload: ApiErrorResponse) {}
}

export const ShowUpdateTaxTypeDialogAction = createAction(
  '[GSettings] Show Update Tax Type Dialog',
  props<{ payload: TaxType }>()
);

export const ShowDeleteTaxTypesDialogAction = createAction(
  '[GSettings] Show Delete Tax Types Dialog',
  props<{ payload: TaxType[] }>()
);

export const UpdateTaxTypeAction = createAction(
  '[GSettings] Update Tax Type',
  props<{ payload: TaxType }>()
);

export const UpdateTaxTypeSuccessAction = createAction(
  '[GSettings] Update Tax Type Success',
  props<{ payload: TaxType }>()
);

export const UpdateTaxTypeFailureAction = createAction(
  '[GSettings] Update Tax Type Failure',
  props<{ payload: ApiErrorResponse }>()
);

export const UpdateTaxTypeCompleteAction = createAction(
  '[GSettings] Update Tax Type Complete',
);

export const DeleteTaxTypesAction = createAction(
  '[GSettings] Delete Tax Types',
  props<{ payload: TaxType[] }>()
);

export const DeleteTaxTypesSuccessAction = createAction(
  '[GSettings] Delete Tax Types Success',
  props<{ payload: TaxType[] }>()
);

export const DeleteTaxTypesFailureAction = createAction(
  '[GSettings] Delete Tax Types Failure',
  props<{ payload: ApiErrorResponse }>()
);

export const DeleteTaxTypesCompleteAction = createAction(
  '[GSettings] Delete Tax Types Complete',
);

export type Actions = OpenInvoiceDialogAction |
  CloseInvoiceDialogAction | UpdateGlobalUserSettings |
  UpdateGlobalUserSettingsSuccess | UpdateGlobalUserSettingsFailed | UpdateGlobalUserSettingsTab |
  GetUserSettingsAction | GetUserSettingsSuccessAction | GetUserSettingsFailureAction | OpenInvoiceDialogSuccessfulAction |
  HideTrialBanner | ShowCloseAccountDialogAction |
  ShowCloseAccountDialogSuccessAction | ShowCloseAccountDialogFailureAction | HideCloseAccountDialogAction |
  SubmitCloseAccountAction | SubmitCloseAccountSuccessAction | SubmitCloseAccountFailureAction | ShowReactivateAccountDialogAction |
  ShowReactivateAccountDialogSuccessAction | HideReactivateAccountDialogAction | UploadLogoRequestAction | UploadLogoSuccessAction | UploadLogoFailureAction |
  typeof GetSettingHistory | typeof GetSettingHistorySuccess | typeof GetSettingHistoryFailure | typeof ResetSettingHistoryStatus | typeof ResetGlobalSettingsStatus;
